// @keyframes loader_5191 {
//     from {
//       opacity: 0;
//     }
  
//     to {
//       opacity: 1;
//     }
//   }
  
//   .square {
//     background: var(--primary);
//     width: 10px;
//     height: 10px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin-top: -5px;
//     margin-left: -5px;
//   }
  
//   #sq1 {
//     margin-top: -25px;
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 0s infinite alternate;
//   }
  
//   #sq2 {
//     margin-top: -25px;
//     animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
//   }
  
//   #sq3 {
//     margin-top: -25px;
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 150ms infinite;
//   }
  
//   #sq4 {
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 225ms infinite;
//   }
  
//   #sq5 {
//     animation: loader_5191 675ms ease-in-out 300ms infinite;
//   }
  
//   #sq6 {
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 375ms infinite;
//   }
  
//   #sq7 {
//     margin-top: 15px;
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 450ms infinite;
//   }
  
//   #sq8 {
//     margin-top: 15px;
//     animation: loader_5191 675ms ease-in-out 525ms infinite;
//   }
  
//   #sq9 {
//     margin-top: 15px;
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 600ms infinite;
//   }
/* HTML: <div class="loader"></div> */
.loader {
  width: 4px;
  color: var(--primary);
  aspect-ratio: 1;
  margin: 6rem auto;
  border-radius: 50%;
  box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
    19px 0     0 5px, 38px 0     0 5px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px;
  transform: translateX(-38px);
  animation: l26 2s infinite linear;
}
@keyframes l26 {
  12.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0     0 5px, 38px 0     0 0px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px}
  25%   {box-shadow: 
    19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 0px}
  50%   {box-shadow: 
    19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
  62.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
    19px 0     0 5px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 5px}
  75%   {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
  87.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 5px, 57px 0     0 0px,
    19px 19px  0 5px, 38px 19px  0 0px, 57px 19px  0 0px}
}